import axios from "axios";

import { API_ROOT } from "./utils";

export const generateAiImage = async (
  userTelegramId: string,
  prompt: string
) => {
  try {
    const response = await axios.post(`${API_ROOT}/api/images`, {
      prompt: prompt,
      telegramId: userTelegramId
    });
    return response.data;
  } catch (error) {
    console.error("generate images error:", error);
    throw error;
  }
};

export const getLatestGeneratedAIImages = async () => {
  try {
    const response = await axios.get(`${API_ROOT}/api/contents/?type=image`);
    return response.data.contents;
  } catch (error) {
    throw error;
  }
};
