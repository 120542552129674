import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { useRecoilState } from "recoil";
import MemeTemplate from "./generateMeme/memeTemplate";
import { AiImage } from "./AiImage/AiImage";
// @ts-ignore
import memegram from "./assets/images/MGM1.png";
// @ts-ignore
import memegramTextLogo from "./assets/images/memegramTextLogo.png";
import GenerateAiMeme from "./generateAiMeme/generateAiMeme";
import CaptionImage from "./CaptionImage/CaptionImage";
import ProfileContent from "./common/Profile/profileContent";
import ProjectContent from "./common/Profile/projectContent";
import PrivacyPolicy from "./common/PrivacyPolicy";
import LoginButton from "./common/Profile/Auth";
import Contests from "./Contests";
import ContestDetail from "./Contests/ContestDetail";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Button
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PageHeader from "./common/pageHeader";
import SocialLinks from "./common/SocialLinks";
import { telegramInfoState } from "./store/atom";
import { getUserTelegramConnection } from "./apis/userApi";

const drawerWidth = 230;

const extractLastPart = (url: string) => {
  const parts = url.split("/");
  return parts[parts.length - 1];
};

const App: React.FC = () => {
  const { user } = useAuth0();
  const [telegramIdInfo, setTelegramIdInfo] = useRecoilState(telegramInfoState);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [mobileView, setMobileView] = React.useState(false);
  const [activeMenu, setActivemenu] = React.useState(
    `/${extractLastPart(document.location.href)}`
  );

  const { data: userData = {} } = useQuery(
    "getUserTelegramConnection",
    () =>
      getUserTelegramConnection({
        email: user?.email!!
      }),
    {
      enabled: !!user?.email,
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    setTelegramIdInfo({
      ...telegramIdInfo,
      currentConnectedTelegramId: userData?.user_id ?? "",
      userData: userData
    });
  }, [userData?.user_id]);

  React.useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth <= 500);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const drawer = (
    <Box
      component="nav"
      sx={{
        width: drawerWidth,
        backgroundColor: "#225093",
        backgroundImage:
          'url("https://assets-global.website-files.com/660a70bef8df59b086b785de/6620219940bfe699d52ccb5e_Memegram%20Web%20Hero-p-800.png")',
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100px"
      }}
    >
      <Toolbar />
      <List>
        {[
          {
            text: "Contests",
            link: "/contests",
            disabled: true,
            highlighted: true
          },
          { text: "Captions", link: "/" },
          { text: "Templates", link: "/template" },
          { text: "AI Meme", link: "/generate-meme" },
          { text: "AI Image", link: "/ai-image" },
          { text: "Profile", link: "/profile" }
        ].map(({ text, link, disabled = false, highlighted = false }) => (
          <div key={text}>
            <ListItem key={text} onClick={() => setMobileOpen(false)}>
              <ListItemButton
                component={Link}
                to={link}
                onClick={() => setActivemenu(link)}
                disabled={!!disabled}
                sx={{
                  color:
                    activeMenu === link ||
                    (activeMenu === "/caption" && link === "/")
                      ? "#ffe263"
                      : "inherit",
                  border: highlighted ? "2px dotted #FFE263" : "none",
                  borderRadius: highlighted ? "12px" : "0"
                }}
              >
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
      {!mobileView && (
        <Link to="https://t.me/memegramAIBot" target="_blank">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#ffe263",
              "&:hover": {
                backgroundColor: "#ffb703"
              },
              color: "#225093",
              position: "fixed",
              bottom: 8,
              width: `${drawerWidth - 16}px`,
              marginLeft: "8px",
              textTransform: "none",
              fontWeight: 700
            }}
            size="medium"
          >
            Telegram Bot 🤖
          </Button>
        </Link>
      )}
    </Box>
  );

  return (
    <Router>
      <Box sx={{ display: "flex", background: "#" }}>
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#3c6dc6"
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={memegram} alt="logo" width={40} height="auto" />{" "}
                <img
                  src={memegramTextLogo}
                  alt="logo"
                  width={120}
                  height="auto"
                  style={{ marginLeft: "4px" }}
                />{" "}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {!mobileView && <SocialLinks mobileView={mobileView} />}

                {/* Authentication */}
                <LoginButton />
              </div>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#36454F",
                color: "white",
                borderRight: " 1px solid white"
              }
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#2c2c2c",
                color: "white",
                borderRight: " 1px solid gray",
                overflow: "hidden"
              }
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1
            // p: 3,
            // width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <Routes>
            <Route
              path="/template"
              element={
                <HeaderWrapper
                  header="Edit Blank Templates"
                  subHeader="Add captions on the blank meme templates"
                >
                  <MemeTemplate />
                </HeaderWrapper>
              }
            />
            <Route
              path="/generate-meme"
              element={
                <HeaderWrapper
                  header="Generate AI Meme"
                  subHeader="Use your imagination and generate funny AI memes"
                >
                  <GenerateAiMeme />
                </HeaderWrapper>
              }
            />
            <Route
              path="/"
              element={
                <HeaderWrapper
                  header="Add Caption On Your Memes"
                  subHeader="Add up and down captions on any images and gifs"
                >
                  <CaptionImage />
                </HeaderWrapper>
              }
            />
            <Route
              path="/caption"
              element={
                <HeaderWrapper
                  header="Add Caption On Your Memes"
                  subHeader="Add up and down captions on any images and gifs"
                >
                  <CaptionImage />
                </HeaderWrapper>
              }
            />
            <Route
              path="/ai-image"
              element={
                <HeaderWrapper
                  header="Generate AI Image"
                  subHeader="Use your imagination and generate AI images"
                >
                  <AiImage />
                </HeaderWrapper>
              }
            />
            <Route
              path="/profile"
              element={
                <HeaderWrapper
                  header="User Profile"
                  subHeader="Find all the contents that belongs to you"
                >
                  <ProfileContent />
                </HeaderWrapper>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <HeaderWrapper
                  header="Privacy Policy"
                  subHeader="Effective starting: Oct 1, 2024"
                >
                  <PrivacyPolicy />
                </HeaderWrapper>
              }
            />
            <Route
              path="/contests"
              element={
                <HeaderWrapper
                  header="Current Live Contests"
                  subHeader="Content creation contests hosted by projects / groups"
                >
                  <Contests />
                </HeaderWrapper>
              }
            />
            <Route path="/contests/:contestId" element={<ContestDetail />} />
            <Route
              path="/project/:projectId"
              element={
                <HeaderWrapper
                  header="Project Profile"
                  subHeader="Find all the project graphical contents"
                >
                  <ProjectContent />
                </HeaderWrapper>
              }
            />
          </Routes>

          {mobileView && (
            <div
              style={{
                backgroundColor: "#ffe263",
                position: "fixed",
                bottom: 0,
                width: "100%",
                padding: "0px 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                zIndex: 1000
              }}
            >
              <SocialLinks mobileView={mobileView} />
            </div>
          )}
        </Box>
      </Box>
    </Router>
  );
};

export default App;

const HeaderWrapper = ({
  header,
  subHeader,
  children
}: {
  header: React.ReactNode;
  subHeader: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        marginBottom: "16px",
        height: {
          xs: "100% !important",
          sm: "calc(100vh-64px) !important"
        }
      }}
    >
      <PageHeader headerText={header} subHeaderText={subHeader}></PageHeader>
      {children}
    </Box>
  );
};
