export const ChartSvg = ({ height, width, color = "#aaa", style = {} }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M8 0V3H7V8H10V3H9V0H8Z" fill="#F44335" />
      <path d="M3 4V5H2V10H3V12H4V10H5V5H4V4H3Z" fill="#36BB91" />
      <path d="M12 6H13V4H14V6H15V11H14V13H13V11H12V6Z" fill="#36BB91" />
    </svg>
  );
};
