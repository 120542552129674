import React from "react";
import Download from "@mui/icons-material/Download";
import IosShareIcon from "@mui/icons-material/IosShare";

import { downloadContent } from "../apis/utils";

const DownloadContent: React.FC<{
  contentUrl: string;
  contentName: string;
}> = ({ contentUrl, contentName }) => {
  return (
    <div
      style={{
        background: "#ffe263",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        padding: "0px 2px"
      }}
    >
      <Download
        onClick={async (e) => {
          await downloadContent(contentUrl, contentName);
        }}
        sx={{ cursor: "pointer" }}
        fontSize="small"
        color="success"
      />
      <IosShareIcon
        sx={{ ml: "8px", cursor: "pointer", paddingBottom: "2px" }}
        onClick={() => {
          if (contentUrl) {
            window.open(contentUrl, "_blank");
          }
        }}
        fontSize="small"
        color="primary"
      />
    </div>
  );
};

export default DownloadContent;
