import React from "react";
import { Box, Typography } from "@mui/material";

const PageHeader: React.FC<{
  headerText: React.ReactNode;
  subHeaderText: React.ReactNode;
}> = ({ headerText, subHeaderText }) => {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          color: "#FFE263",
          padding: "16px 16px",
          marginBottom: "16px"
        }}
      >
        <Typography variant="h4" textAlign={"center"}>
          {headerText}
        </Typography>

        <Typography
          variant="subtitle1"
          textAlign={"center"}
          sx={{ opacity: 0.9, fontWeight: 500, color: "#FFF" }}
        >
          {subHeaderText}
        </Typography>
      </Box>
    </>
  );
};

export default PageHeader;
