import React from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
  Box
} from "@mui/material";

const privacyInformationStyle = {
  color: "#fff !important",
  "& > p": {
    color: "#f5f5f5"
  },
  "& > span": {
    fontWeight: 700
  }
};

const PrivacyPolicy: React.FC = () => {
  return (
    <Container
      sx={{
        color: "#fff"
      }}
    >
      <Box my={4}>
        <Typography variant="subtitle1" fontWeight={700} gutterBottom>
          Last Updated: 2024-10-01
        </Typography>

        <Typography variant="body1" paragraph>
          Thank you for using our Meme Generation App ("we," "us," or "our").
          This privacy policy explains how we collect, use, and protect your
          information when you use our app. By using the app, you agree to the
          terms of this Privacy Policy.
        </Typography>

        <Typography variant="h6" gutterBottom color={"#FFE263"}>
          1. Information We Collect
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              sx={privacyInformationStyle}
              primary="1. Email Address"
              secondary="We collect your email when you sign up using email or Google login (powered by Auth0)."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              sx={privacyInformationStyle}
              primary="2. Profile Information"
              secondary="We collect your profile name."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              sx={privacyInformationStyle}
              primary="3.Telegram Information"
              secondary="If you choose to connect via Telegram, we collect your Telegram ID and Telegram user information."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              sx={privacyInformationStyle}
              primary="4. User Content"
              secondary="We store the memes, AI-generated images, and other content you create and upload on the app."
            />
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom color={"#FFE263"}>
          2. What We Do Not Collect
        </Typography>
        <Typography variant="body1" paragraph>
          - We do not collect or store passwords or any secret keys related to
          your accounts.
          <br />- We do not collect or store credit card, bank card, or other
          payment details.
        </Typography>

        <Typography variant="h5" gutterBottom color={"#FFE263"}>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          - We do not share, sell, or rent your personal data with third
          parties.
          <br />
          - Your email is only used for internal purposes, such as account
          management, notifications related to the app, and password recovery.
          <br />- We do not spam our users with unsolicited emails or messages.
        </Typography>

        <Typography variant="h5" gutterBottom color={"#FFE263"}>
          4. Data Storage
        </Typography>
        <Typography variant="body1" paragraph>
          We store your content (e.g., memes, AI-generated images) to provide
          you with a seamless experience and allow you to manage your profile.
          All user data is stored securely and in compliance with applicable
          data protection laws.
        </Typography>

        <Typography variant="h5" gutterBottom color={"#FFE263"}>
          5. Account Deletion
        </Typography>
        <Typography variant="body1" paragraph sx={{ marginBottom: 0 }}>
          You can delete your account at any time by contacting us via Telegram
          or email.
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Via Telegram"
              secondary={
                <Link href="https://t.me/memegramAI" target="_blank">
                  https://t.me/memegramAI
                </Link>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Via Email"
              secondary={
                <Link href="mailto:memegramai@gmail.com">
                  memegramai@gmail.com
                </Link>
              }
            />
          </ListItem>
        </List>

        <Typography variant="body1" paragraph>
          Once you request deletion, we will permanently delete all your data
          from our system. This action is irreversible.
        </Typography>

        <Typography variant="h5" gutterBottom color={"#FFE263"}>
          6. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns regarding this Privacy Policy or
          your personal data, please contact us via Telegram or email.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
