import axios from "axios";
import { API_ROOT } from "./utils";

export const getProjectInfoById = async (projectId: string) => {
  try {
    const response = await axios.get(`${API_ROOT}/api/projects/${projectId}`);
    return response.data || {};
  } catch (error) {
    throw error;
  }
};
