import axios from "axios";
import { API_ROOT } from "./utils";

export const captionImage = async (
  userTelegramId: string,
  imageData: {
    imageUrl: string;
    upText: string;
    downText: string;
  },
  file: File
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upText", imageData.upText);
    formData.append("downText", imageData.downText);
    formData.append("telegramId", userTelegramId);
    const response = await fetch(`${API_ROOT}/api/images/caption`, {
      method: "POST",
      body: formData
    });

    if (!response.ok) {
      throw new Error("Failed to get response");
    }
    return response.json();
  } catch (error) {
    console.error("generate images error:", error);
    throw error;
  }
};

export const getLatestGeneratedCaptions = async () => {
  try {
    const response = await axios.get(`${API_ROOT}/api/contents/?type=caption`);
    return response.data.contents;
  } catch (error) {
    throw error;
  }
};
