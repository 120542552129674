import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from "./Avatar";

const AuthButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <Avatar />;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => loginWithRedirect()}
      sx={{
        width: "100%",
        color: "#225093",
        backgroundColor: "#ffe263",
        "&:hover": {
          backgroundColor: "white",
          color: "#225093"
        }
      }}
      size="small"
    >
      Log in
    </Button>
  );
};

export default AuthButton;
