import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material";
import { useQuery } from "react-query";
import { memePrompt, telegramInfoState } from "../store/atom";
import { useRecoilState } from "recoil";
import { generateAiMeme } from "../apis/generateAiMeme";
import ContentList from "../common/ContentList";
import DownloadContent from "../common/DownloadContent";
import { getAiMeme } from "../apis/generateAiMeme";

const GenerateAiMeme: React.FC = () => {
  const [generatedImageUrl, setGeneratedImageUrl] = useState<string | null>();
  const [telegramIdInfo] = useRecoilState(telegramInfoState);
  const [input, setInput] = useRecoilState(memePrompt);
  const [loading, setLoading] = useState<boolean>(false);
  const [generateError, setGenerateError] = useState<boolean>(false);

  const userTelegramId = telegramIdInfo?.currentConnectedTelegramId;

  const { data = [], isLoading } = useQuery("memes", getAiMeme, {
    enabled: true,
    retry: false,
    refetchOnWindowFocus: false
  });

  const handleChangePrompt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleGenerate = async () => {
    try {
      setLoading(true);
      const response = await generateAiMeme(userTelegramId, input.prompt);
      if (!response || !response.image) {
        setGeneratedImageUrl(null);
        setGenerateError(true);
      } else {
        setGeneratedImageUrl(response.image);
        setGenerateError(false);
      }
    } catch (error) {
      console.error("Error generating image:", error);
      setGeneratedImageUrl(null);
      setGenerateError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          color: "white",
          padding: "0px 16px"
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              placeholder="Enter prompt"
              fullWidth
              multiline
              minRows={6}
              maxRows={10}
              name="prompt"
              onChange={handleChangePrompt}
              InputLabelProps={{ style: { color: "white" } }}
              sx={{
                height: "auto",
                borderRadius: 1,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "2px solid #ffe263 !important",
                    borderRadius: "8px"
                  },
                  "&:hover fieldset": {
                    border: "2px solid #ffe263 !important",
                    borderRadius: "8px"
                  }
                }
              }}
              inputProps={{ style: { color: "white" } }}
            />
            <Button
              variant="contained"
              sx={{
                mt: "8px",
                height: "auto",
                width: "100%",
                backgroundColor: "#ffe263",
                border: "2px solid #ffe263 !important",
                "&:hover": {
                  backgroundColor: "#ffb703"
                },
                color: !input.prompt
                  ? "#ffe263 !important"
                  : "#225093 !important"
              }}
              size="large"
              onClick={handleGenerate}
              disabled={loading || !input.prompt}
            >
              {loading ? "Generating..." : "Generate Meme"}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                padding: "8px",
                borderRadius: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "204px",
                border: "2px solid #ffe263 !important"
              }}
            >
              {!generatedImageUrl && !loading && !generateError && (
                <Typography>Your meme will display here</Typography>
              )}
              {input.prompt && generateError && !loading && (
                <Container
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "red"
                  }}
                >
                  <Typography variant="h6">
                    Couldn't generate image, please try again.
                  </Typography>
                </Container>
              )}
              {!input.prompt && !loading && generateError && (
                <Container
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "red"
                  }}
                >
                  <Typography variant="h6">
                    Couldn't generate meme without prompt, Please try again by
                    providing prompt.
                  </Typography>
                </Container>
              )}
              {loading && (
                <Container
                  component="main"
                  maxWidth="xl"
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80%"
                  }}
                >
                  <CircularProgress />
                </Container>
              )}
              {generatedImageUrl && !loading && (
                <Box
                  sx={({ breakpoints }) => ({
                    background: `url("${generatedImageUrl}")`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    [breakpoints.down("sm")]: {
                      height: "300px",
                      width: "300px"
                    },
                    [breakpoints.up("sm")]: {
                      height: "450px",
                      width: "450px"
                    }
                  })}
                >
                  <Box
                    sx={{
                      m: "8px 0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end"
                    }}
                  >
                    <DownloadContent
                      contentName={input.prompt}
                      contentUrl={generatedImageUrl}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* List AI meme */}
      <ContentList data={data} isLoading={isLoading} type={"meme"} />
    </>
  );
};

export default GenerateAiMeme;
