import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Grid, Typography, Tabs, Tab, Button } from "@mui/material";
import ProjectProfile from "./projectProfile";
import { useRecoilState } from "recoil";

// @ts-ignore
import { Link } from "react-router-dom";
import ContentList from "../ContentList";
import { getProjectInfoById } from "../../apis/projectApi";
import { searchContents } from "../../apis/searchContents";

type Content = "image" | "template" | "meme" | "caption";

const ProfileContent: React.FC = () => {
  const params = useParams();
  const [tabValue, setTabValue] = useState<Content>("image");

  const { projectId = "" } = params || {};

  const { data: projectData = {}, refetch: refetchProject } = useQuery(
    "getProjectInfoById",
    () => getProjectInfoById(projectId),
    {
      enabled: !!projectId
    }
  );

  const {
    data = {},
    isLoading,
    isFetching
  } = useQuery(
    `searchContents-${projectId}-${tabValue}`,
    () => searchContents(projectId, tabValue),
    {
      enabled: !!projectId,
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const handleTabChange = (value: Content) => {
    setTabValue(value);
  };

  const { contents = [], count = 0 } = data;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          color: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <ProjectProfile projectData={projectData} />
        </Box>
        <>
          <Box
            sx={{
              mt: 3,
              width: {
                xs: "100%",
                sm: "90%"
              }
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(_e, value) => handleTabChange(value)}
              aria-label="Contents Category tabs"
              centered
              allowScrollButtonsMobile
              sx={{
                width: "100%",
                "& button": {
                  minWidth: 68
                },
                ".MuiTab-root": {
                  color: "#ffffffcc",
                  textTransform: "none",
                  margin: 0,
                  padding: {
                    xs: "0px",
                    sm: "16px"
                  },
                  fontSize: {
                    xs: "12px",
                    sm: "14px"
                  }
                },
                ".Mui-selected": {
                  color: "#ffe263 !important",
                  padding: {
                    xs: "0px",
                    sm: "16px"
                  },
                  fontSize: {
                    xs: "12px",
                    sm: "14px"
                  }
                }
              }}
            >
              <Tab label="Images" value={"image"} />
              <Tab label="Templates" value={"template"} />
              <Tab label="Captions" value={"caption"} />
              <Tab label="Memes" value={"meme"} />
              <Tab label="Saved" value={"saved"} />
            </Tabs>
          </Box>
          {isLoading || isFetching ? (
            <Box
              sx={{
                margin: "0px 16px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ width: "90%", p: 2 }}>
              <Grid container spacing={2}>
                {/* If TG not connected, show the recommendation message */}
                {!projectData?.chat_id ? (
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <b>
                        <p>
                          Connect your Telegram group and get full access to
                          meme world while using Telegram!
                        </p>
                      </b>
                      Simply add MemegramAI Bot in your project group. Then you
                      are fully ready!
                      <br />
                      <Link to="https://t.me/memegramAIBot" target="_blank">
                        <Button
                          variant="outlined"
                          sx={{
                            marginTop: "16px",
                            textTransform: "none",
                            fontWeight: 700,
                            border: "1px solid #ffe263",
                            color: "#ffe263"
                          }}
                          size="small"
                        >
                          Telegram Bot 🤖
                        </Button>
                      </Link>
                    </Typography>
                  </Grid>
                ) : (
                  <ContentList
                    data={contents}
                    isLoading={isLoading}
                    type={tabValue}
                  />
                )}
              </Grid>
            </Box>
          )}
        </>
      </Box>
    </>
  );
};

export default ProfileContent;
