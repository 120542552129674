import axios from "axios";
import { API_ROOT } from "./utils";

export const createUserTelegramConnection = async (payload: {
  email: string;
  telegramId: string;
}) => {
  try {
    const response = await axios.post(`${API_ROOT}/api/users`, payload);
    return response.data;
  } catch (error) {
    console.error("Telegram connection error:", error);
    throw error;
  }
};

export const getUserTelegramConnection = async (params: { email: string }) => {
  try {
    const { email } = params;
    const response = await axios.get(`${API_ROOT}/api/users/?email=${email}`);
    return response.data.user || {};
  } catch (error) {
    throw error;
  }
};

export const deleteUserTelegramConnection = async (params: {
  email: string;
  telegramId: string;
}) => {
  try {
    const { email, telegramId } = params;
    await axios.delete(
      `${API_ROOT}/api/users/?email=${email}&telegramId=${telegramId}`
    );
  } catch (error) {
    throw error;
  }
};
