import React from "react";
// @ts-ignore
import { truncate } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Grid, Typography } from "@mui/material";
import DownloadContent from "../common/DownloadContent";

const ContentList: React.FC<{
  data: any[];
  isLoading: boolean;
  type: "caption" | "meme" | "template" | "image" | "saved";
}> = ({ data = [], isLoading = false, type }) => {
  if (isLoading || !data) {
    return (
      <ContentWrapper>
        <CircularProgress
          sx={{ margin: "0 auto", marginTop: "16px", color: "#3c6dc6" }}
        />
      </ContentWrapper>
    );
  }

  const getTitleByType = () => {
    switch (type) {
      case "caption":
        return "Latest 50 Generated Captions";
      case "image":
        return "Latest 50 Generated AI Images";
      case "meme":
        return "Latest 50 Generated AI Memes";
      case "template":
        return "Latest 50 Generated Templates";
      case "saved":
        return "Latest 50 Saved Contents";
    }
  };

  return (
    <ContentWrapper>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h6">{getTitleByType()}</Typography>
        {data.length === 0 && (
          <Typography
            sx={{
              marginTop: "8px",
              color: "#ffe263"
            }}
          >
            Data is not available
          </Typography>
        )}
      </Grid>

      {data.map((content: any, index: number) => {
        const contentPrompt = getCommandReplacedPrompt(content?.prompt);
        return (
          <Grid item key={index} xs={6} sm={4} md={4} lg={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid white",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 2,
                minHeight: "200px",
                height: "100%",
                "&:hover": {
                  transform: "scale(1.05)"
                }
              }}
            >
              <Box
                height="100%"
                width="100%"
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "& img, & video": {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: 2,
                    borderBottom: "none",
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                  }
                }}
              >
                {content.url.includes(".mp4") ? (
                  <video src={content.url} autoPlay loop muted playsInline />
                ) : (
                  <img src={content.url} alt={content.prompt} />
                )}
              </Box>
              {content.prompt ? (
                <Box
                  height="auto"
                  width={"100%"}
                  sx={{
                    position: "relative",
                    backgroundColor: "#36454F",
                    borderRadius: 2
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={7}
                      md={8}
                      sx={{
                        ml: 1,
                        mt: 0.5,
                        mb: 0.5,
                        height: "100%",
                        width: "100%"
                      }}
                    >
                      <Typography sx={{ fontSize: 13 }}>
                        {contentPrompt ? (
                          <>
                            <b>Prompt:&nbsp;</b>
                            {truncate(contentPrompt, {
                              length: 30,
                              separator: " "
                            })}
                          </>
                        ) : (
                          <>
                            <b>Command:&nbsp;</b>
                            {truncate(content?.prompt, {
                              length: 20,
                              separator: " "
                            })}
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={4}
                      sx={{
                        position: "absolute",
                        right: 5,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        height: "100%",
                        width: "100%"
                      }}
                    >
                      <DownloadContent
                        contentName={content.prompt}
                        contentUrl={content.url}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box
                  height="auto"
                  width={"100%"}
                  sx={{
                    position: "relative",
                    backgroundColor: "#36454F",
                    borderRadius: 2
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{
                        position: "absolute",
                        right: 5,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                        bottom: "16px"
                      }}
                    >
                      <DownloadContent
                        contentName={content.prompt}
                        contentUrl={content.url}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Grid>
        );
      })}
    </ContentWrapper>
  );
};

export default ContentList;

const ContentWrapper: React.FC<{ children: any }> = ({ children }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "auto",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        color: "white",
        mb: 5
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: { xs: "center", sm: "flex-start" },
          maxWidth: "100%",
          "& .MuiGrid-item": {},
          marginTop: "0px"
        }}
      >
        {children}
      </Grid>
    </Box>
  );
};

const getCommandReplacedPrompt = (prompt = "") => {
  const commandReplacedPrompt = prompt
    ?.replace("/mark", "")
    ?.replace("/convert", "");
  return commandReplacedPrompt?.length < 5 ? "" : commandReplacedPrompt;
};
