import React from "react";
import { TwitterIconSVG } from "./svg/twitterIconSvg";
import { TelegramSvg } from "./svg/telegramSvg";
import { WebsiteIconSVG } from "./svg/websiteIconSvg";
import { ChartSvg } from "./svg/chartSvg";

type Props = {
  projectData: any;
};
const SocialLinks: React.FC<Props> = ({ projectData = {} }) => {
  if (!projectData?.chat_id) {
    return <div />;
  }
  const telegram = projectData?.telegram;
  const website = projectData?.website;
  const twitter = projectData?.twitter;
  const contract = projectData?.contract;
  const chain = projectData?.chain;
  const chart = contract
    ? `https://www.dextools.io/app/en/${getChainForDextoolsUrl(
        chain
      )}/pair-explorer/${contract}`
    : "";
  return (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        justifyContent: "flex-start",
        right: "12px",
        bottom: "0px",
        marginTop: "8px",
        border: "1px solid #FFE263",
        borderRadius: "24px",
        padding: "4px 8px 0px 8px",
        background: "#36454f"
      }}
    >
      {telegram && (
        <a href={projectData?.telegram} target="_blank">
          <TelegramSvg width="28" height="28" style={{ marginRight: "4px" }} />
        </a>
      )}
      {website && (
        <a href="https://xxx.gitbook.io/" target="_blank">
          <WebsiteIconSVG
            width="28"
            height="28"
            style={{ marginRight: "4px" }}
          />
        </a>
      )}
      {twitter && (
        <a href="https://x.com/xxx" target="_blank">
          <TwitterIconSVG
            width="28"
            height="28"
            style={{ marginRight: "6px" }}
          />
        </a>
      )}
      {chart && (
        <a href={chart} target="_blank">
          <ChartSvg
            width="24"
            height="24"
            style={{ marginRight: "4px", marginTop: "2px" }}
          />
        </a>
      )}
    </div>
  );
};

export default SocialLinks;

export const getChainForDextoolsUrl = (chain = "") => {
  const lowerCaseChain = chain?.toLowerCase();
  if (["bnb", "bsc", "binance"].includes(lowerCaseChain)) {
    return "bnb";
  }
  if (["eth", "ethereum", "ether"].includes(lowerCaseChain)) {
    return "ether";
  }
  if (["polygon"].includes(lowerCaseChain)) {
    return "polygon";
  }
  if (["base"].includes(lowerCaseChain)) {
    return "base";
  }
  if (["sol", "solana"].includes(lowerCaseChain)) {
    return "solana";
  }
  if (["tron"].includes(lowerCaseChain)) {
    return "tron";
  }
  if (["arb", "arbitrum"].includes(lowerCaseChain)) {
    return "arbitrum";
  }
  if (["ton"].includes(lowerCaseChain)) {
    return "ton";
  }
  if (["avalanche", "avax"].includes(lowerCaseChain)) {
    return "avalanche";
  }
  if (["optimism", "opti"].includes(lowerCaseChain)) {
    return "optimism";
  }
  return "ether";
};
