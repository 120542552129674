import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Typography,
  Stack,
  Button,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const AvatarDemo = () => {
  const { user, loginWithRedirect, isAuthenticated, logout } = useAuth0();
  const [avatarEl, setAvatarEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleAvatarClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarEl(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };

  const open = Boolean(avatarEl);
  const id = open ? "simpe-popover" : undefined;

  return (
    <div>
      <Stack direction="row" spacing={1}>
        <Button size="small" aria-describedby={id} onClick={handleAvatarClick}>
          <Avatar src={user?.picture} sx={{ height: 36, width: 36 }}>
            {user?.name?.charAt(0) ?? "A"}
          </Avatar>
          <KeyboardArrowDownIcon htmlColor="#ffe263" />
        </Button>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={avatarEl}
        onClose={handleAvatarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <List disablePadding sx={{ background: "#3c6dc6", color: "#ffe263" }}>
          <ListItem disablePadding>
            <Link
              to={"/profile"}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItemButton onClick={handleAvatarClose}>
                <ListItemText
                  primary={
                    <Typography fontWeight={500} variant="body2">
                      Visit Profile
                    </Typography>
                  }
                  sx={{ fontSize: "11px" }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              <ListItemText
                primary={
                  <Typography
                    fontWeight={500}
                    variant="body2"
                    sx={{ color: "#ff3333" }}
                  >
                    Logout
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default AvatarDemo;
