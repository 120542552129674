import React from "react";
import { Box, Grid, Typography, Alert, Button } from "@mui/material";
// @ts-ignore
import { truncate } from "lodash";
import { Link } from "react-router-dom";
import SocialLinks from "./social";
import { TelegramSvg } from "./svg/telegramSvg";

type ProfileProps = {
  projectData: any;
};
const ProjectProfile: React.FC<ProfileProps> = ({ projectData }) => {
  if (!projectData?.chat_id) {
    return <div />;
  }
  const projectTelegram = projectData?.telegram;
  const isSocialAdded =
    (projectData?.telegram || projectData?.website)?.length > 0;
  return (
    <Box
      sx={{
        border: "none",
        borderRadius: 4,
        backgroundColor: "#245c9c",
        width: {
          xs: "82%",
          sm: "90%"
        },
        padding: 2
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={12} sm={12} md={8}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Box>
              {projectData?.logo_url ? (
                <Box
                  component="img"
                  src={projectData.logo_url}
                  alt={projectData.name}
                  sx={{
                    borderRadius: "50%",
                    border: "2px solid #EEF263",
                    width: {
                      xs: "60px",
                      sm: "100px"
                    },
                    height: { xs: "60px", sm: "100px" },
                    objectFit: "cover",
                    margin: 1,
                    mr: 3
                  }}
                />
              ) : (
                <Box
                  sx={{
                    borderRadius: "50%",
                    width: "100px",
                    height: "100px",
                    backgroundColor: "#ffffff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "2rem",
                    color: "#245c9c",
                    margin: 1,
                    mr: 3
                  }}
                >
                  {(
                    projectData?.name ||
                    projectData?.chat_title ||
                    projectData?.chat_username
                  )
                    ?.charAt(0)
                    .toUpperCase()}
                </Box>
              )}
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{ color: "white", display: "flex", alignItems: "center" }}
              >
                {truncate(
                  projectData?.name ||
                    projectData?.chat_title ||
                    projectData?.chat_username,
                  {
                    length: 40
                  }
                )}
              </Typography>
              {projectData?.symbol && (
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  {projectData?.symbol?.startsWith("$")
                    ? projectData?.symbol
                    : `$${projectData?.symbol}`}
                </Typography>
              )}
              {isSocialAdded ? (
                <SocialLinks projectData={projectData} />
              ) : (
                <Typography variant="body1">
                  <Link to="https://t.me/memegramAIBot" target="_blank">
                    <Button
                      variant="outlined"
                      sx={{
                        marginTop: "16px",
                        textTransform: "none",
                        fontWeight: 700,
                        border: "1px solid #ffe263",
                        color: "#ffe263"
                      }}
                      size="small"
                    >
                      Add social data 🤖
                    </Button>
                  </Link>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box>
            <Typography
              sx={{
                color: "white",
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 700
              }}
            >
              Telegram Information
              <TelegramSvg
                width="22"
                height="22"
                style={{ marginLeft: "4px" }}
              />
            </Typography>
            <Typography variant="subtitle1" style={{ color: "white" }}>
              Telegram:{" "}
              {!!projectTelegram && (
                <Link
                  to={projectTelegram}
                  target="_blank"
                  style={{
                    color: "inherit",
                    padding: "2px",
                    textUnderlineOffset: "4px"
                  }}
                >
                  {projectTelegram}
                </Link>
              )}
            </Typography>
            <Typography variant="subtitle1" style={{ color: "white" }}>
              Project Id: <code>{projectData.chat_id}</code>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ProjectProfile;
