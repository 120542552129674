// @ts-ignore

import { Link } from "react-router-dom";
import { Button } from "@mui/material";

type Props = {
  mobileView: boolean;
};

const SocialLinks: React.FC<Props> = ({ mobileView }) => {
  return (
    <>
      {mobileView && (
        <Link
          to="https://t.me/memegramAIBot"
          target="_blank"
          style={{ width: "70%", textDecoration: "none", marginLeft: 10 }}
        >
          <Button
            sx={{
              border: "none",
              width: mobileView ? "auto" : "100%",
              textTransform: "none",
              fontWeight: 700,
              fontSize: 14
            }}
          >
            Telegram Bot 🤖
          </Button>
        </Link>
      )}
      <Link
        to="https://t.me/memegramAI"
        target="_blank"
        style={{ marginTop: "8px" }}
      >
        <img
          width="30"
          height="30"
          src="https://img.icons8.com/color/230/telegram-app--v1.png"
          alt="telegram-app--v1"
          style={{ marginRight: "8px" }}
        />
      </Link>
      <Link
        to="https://x.com/memegramAI"
        target="_blank"
        style={{ marginTop: "8px" }}
      >
        <img
          width="30"
          height="30"
          src="https://img.icons8.com/color/230/twitterx--v1.png"
          alt="twitterx--v1"
          style={{ marginRight: "8px" }}
        />
      </Link>
      <Link
        to="https://memegram.gitbook.io/"
        target="_blank"
        style={{ marginTop: "8px" }}
      >
        <img
          width="30"
          height="30"
          src="https://img.icons8.com/color/96/documents.png"
          alt="documents"
          style={{ marginRight: "8px" }}
        />
      </Link>
      <Link
        to="https://memegram.ai"
        target="_blank"
        style={{ marginTop: "8px", marginRight: 20 }}
      >
        <img
          width="28"
          height="28"
          src="https://img.icons8.com/dusk/64/domain.png"
          alt="domain"
        />
      </Link>
    </>
  );
};

export default SocialLinks;
