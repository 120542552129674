import { API_ROOT } from "./utils";

export const fetchImages = async () => {
  try {
    const res = await fetch(`${API_ROOT}/api/templates`, {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    });
    const data = await res.json();
    return data.templates;
  } catch (error) {
    console.error("Fetch images error:", error);
  }
};

export const captionTemplateImage = async (boxValues: any) => {
  try {
    const res = await fetch(`${API_ROOT}/api/templates`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(boxValues)
    });
    if (!res.ok) {
      throw new Error("Failed to get response");
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Fetch images error:", error);
  }
};
