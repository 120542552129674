import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { useQuery } from "react-query";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Container,
  CircularProgress
} from "@mui/material";
import { generateAiImage } from "../apis/aiImage";
import DownloadContent from "../common/DownloadContent";
import ContentList from "../common/ContentList";
import { getLatestGeneratedAIImages } from "../apis/aiImage";
import { imagePrompt, telegramInfoState } from "../store/atom";

export const AiImage: React.FC = () => {
  const [input, setInput] = useRecoilState(imagePrompt);
  const [telegramIdInfo] = useRecoilState(telegramInfoState);
  const [generatedImageUrl, setGeneratedImageUrl] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [generateError, setGenerateError] = useState<boolean>(false);

  const userTelegramId = telegramIdInfo?.currentConnectedTelegramId;

  const { data = [], isLoading } = useQuery(
    "getLatestGeneratedAIImages",
    getLatestGeneratedAIImages,
    {
      enabled: true,
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const handleChangePrompt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleGenerate = async () => {
    try {
      setLoading(true);
      const response = await generateAiImage(userTelegramId, input.prompt);
      if (!response || !response.image) {
        setGeneratedImageUrl(null);
        setGenerateError(true);
      } else {
        setGeneratedImageUrl(response.image);
        setGenerateError(false);
      }
    } catch (error) {
      console.error("Error generating image:", error);
      setGeneratedImageUrl(null);
      setGenerateError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        padding: "0px 16px"
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            placeholder="Enter prompt"
            fullWidth
            multiline
            minRows={6}
            maxRows={10}
            name="prompt"
            onChange={handleChangePrompt}
            InputLabelProps={{ style: { color: "white" } }}
            sx={{
              height: "auto",
              borderRadius: 1,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "2px solid #ffe263",
                  borderRadius: "8px"
                },
                "&:hover fieldset": {
                  border: "2px solid #ffe263",
                  borderRadius: "8px"
                }
              }
            }}
            inputProps={{ style: { color: "white" } }}
          />
          <Button
            variant="contained"
            sx={{
              mt: "12px",
              height: "auto",
              width: "100%",
              border: "2px solid #ffe263 !important",
              backgroundColor: "#ffe263",
              "&:hover": {
                backgroundColor: "#ffb703"
              },
              color: !input.prompt ? "#ffe263 !important" : "#225093 !important"
            }}
            size="large"
            onClick={handleGenerate}
            disabled={loading || !input.prompt}
          >
            {loading ? "Generating..." : "Generate Image"}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              border: "2px solid #ffe263",
              padding: "8px",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "200px",
              color: "#ffffff"
            }}
          >
            {!generatedImageUrl && !loading && !generateError && (
              <Typography>Your Image will display here:</Typography>
            )}
            {input.prompt && generateError && !loading && (
              <Container
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "red"
                }}
              >
                <Typography variant="h6">
                  Couldn't generate image, please try again.
                </Typography>
              </Container>
            )}
            {!input.prompt && !loading && generateError && (
              <Container
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "red"
                }}
              >
                <Typography variant="h6">
                  Couldn't generate image without prompt, Please try again by
                  providing prompt
                </Typography>
              </Container>
            )}
            {loading && (
              <Container
                component="main"
                maxWidth="xl"
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80%"
                }}
              >
                <CircularProgress />
              </Container>
            )}
            {generatedImageUrl && !loading && (
              <Box
                sx={({ breakpoints }) => ({
                  background: `url("${generatedImageUrl}")`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  [breakpoints.down("sm")]: {
                    height: "300px",
                    width: "300px"
                  },
                  [breakpoints.up("sm")]: {
                    height: "450px",
                    width: "450px"
                  }
                })}
              >
                <Box
                  sx={{
                    m: "8px 0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end"
                  }}
                >
                  <DownloadContent
                    contentName={input.prompt}
                    contentUrl={generatedImageUrl}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        <ContentList data={data} isLoading={isLoading} type={"image"} />
      </Grid>
    </Box>
  );
};
